// TODO: get color values from theme
const getBannerStyles = (theme) => ({
    byVariant: {
        neutral: {
            iconColor: '#6488F0',
            closeButtonColor: '#ABB4BB',
            backgroundColor: '#F8F9FA',
            borderBottomColor: '#EFF1F3'
        },
        info: {
            iconColor: '#4B6ED2',
            closeButtonColor: '#4B6ED2',
            backgroundColor: '#E6EDFE',
            borderBottomColor: '#C6D4F9'
        },
        success: {
            iconColor: '#30A46C',
            closeButtonColor: '#30A46C',
            backgroundColor: '#ECFBF1',
            borderBottomColor: '#D6F2DF'
        },
        warning: {
            iconColor: '#FFA01C',
            closeButtonColor: '#FFA01C',
            backgroundColor: '#FFF9ED',
            borderBottomColor: '#FFF4D5'
        },
        error: {
            iconColor: '#E5484D',
            closeButtonColor: '#EB9091',
            backgroundColor: '#FFF8F8',
            borderBottomColor: '#FFE5E5'
        },
        history: {
            iconColor: theme.palette.text.primary,
            closeButtonColor: theme.palette.text.primary,
            backgroundColor: '#EFF1F3',
            borderBottomColor: '#D6DCE0'
        }
    }
});
export { getBannerStyles, };
