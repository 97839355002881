import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import { ContainerRow } from '@components/styled';
import Icon from '@shared/ui/icons/Icon';
import { ReactComponent as AlertIcon } from '@icons/wolfkit-solid/alert-circle-solid.svg';
import { ReactComponent as CloseIcon } from '@icons/wolfkit-light/xmark-light.svg';
import { getBannerStyles } from './styles';
const Container = styled(ContainerRow)((props) => (Object.assign({ width: '100%', height: 'auto', alignItems: 'center', boxSizing: 'border-box', padding: '0 32px', gap: props.theme.spacing_sizes.m, background: props.bannerStyles.backgroundColor, borderRadius: props.theme.shape.borderRadius * 2, borderBottom: `1px solid ${props.bannerStyles.borderBottomColor}` }, props.elevated && ({
    borderBottomWidth: '2px',
}))));
const MessageContainer = styled(ContainerRow)((props) => ({
    flex: 1,
    width: 'auto',
    height: 'auto',
    alignItems: 'center',
    gap: props.theme.spacing_sizes.m,
}));
const ButtonsContainer = styled(ContainerRow)(() => ({
    flexShrink: 0,
    width: 'auto',
    height: 'auto',
    gap: '16px',
}));
const AdditionalButtonsContainer = styled(ContainerRow)((props) => ({
    width: 'auto',
    height: 'auto',
    gap: props.theme.spacing_sizes.xs,
}));
const CloseButton = styled.div(() => ({
    cursor: 'pointer',
}));
const Banner = ({ text, fontSize = '14px', size = 'small', variant = 'neutral', elevated = false, additionalButtons = undefined, customIcon = undefined, onCloseClick = undefined, hideIcon = false, hideCloseButton = false, className = undefined, }) => {
    const theme = useTheme();
    const [showBanner, setShowBanner] = useState(true);
    const height = useMemo(() => {
        if (size === 'small') {
            return '70px';
        }
        return '80px';
    }, [size]);
    const bannerStyles = getBannerStyles(theme).byVariant[variant];
    const handleCloseClick = (event) => {
        if (onCloseClick) {
            onCloseClick(event);
        }
        else {
            setShowBanner(false);
        }
    };
    if (!showBanner) {
        return null;
    }
    return (_jsxs(Container, { className: className, bannerStyles: bannerStyles, variant: variant, elevated: elevated, style: {
            height,
        }, children: [_jsxs(MessageContainer, { children: [!hideIcon && (customIcon || (_jsx(Icon, { size: 16, color: bannerStyles.iconColor, IconComponent: AlertIcon }))), _jsx(Typography, { fontSize: fontSize, fontWeight: 500, children: text })] }), _jsxs(ButtonsContainer, { children: [_jsx(AdditionalButtonsContainer, { children: additionalButtons }), !hideCloseButton && (_jsx(CloseButton, { onClick: handleCloseClick, children: _jsx(Icon, { size: 16, color: bannerStyles.closeButtonColor, IconComponent: CloseIcon }) }))] })] }));
};
export default Banner;
